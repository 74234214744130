@import '../node_modules/ngx-sharebuttons/themes/circles.scss';

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fff;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

input {
  font-family: 'Barlow', sans-serif;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

a {
  text-decoration: none;
  color: #f89223;
  transition: color 0.2s ease;
}

a:hover {
  color: #e17909;
}

.mat-drawer-backdrop {
  background-color: #000;
  opacity: 0;
  transition: opacity 400ms !important;

  &.mat-drawer-shown {
    opacity: 0.4;
  }
}

share-buttons .sb-group {
  display: block !important;
}

app-recipe-details .details-description {
  p {
    margin: 4px 0 12px 0;
  }

  ul,
  ol {
    margin: 12px 0 12px 0;

    ul,
    ol {
      margin: 0;
    }
  }

  h3 {
    margin: 12px 0 4px 0;
  }
}

// Desktop
@media (min-width: 1025px) {
  .for-mobile:not(.for-desktop) {
    display: none !important;
  }

  .for-tablet:not(.for-desktop) {
    display: none !important;
  }
}

// Tablet
@media (min-width: 769px) and (max-width: 1024px) {
  .for-desktop:not(.for-tablet) {
    display: none !important;
  }

  .for-mobile:not(.for-tablet) {
    display: none !important;
  }
}

// Mobile
@media (max-width: 768px) {
  .for-desktop:not(.for-mobile) {
    display: none !important;
  }

  .for-tablet:not(.for-mobile) {
    display: none !important;
  }
}